exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contato-index-tsx": () => import("./../../../src/pages/contato/index.tsx" /* webpackChunkName: "component---src-pages-contato-index-tsx" */),
  "component---src-pages-faxe-index-tsx": () => import("./../../../src/pages/faxe/index.tsx" /* webpackChunkName: "component---src-pages-faxe-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-leoes-index-tsx": () => import("./../../../src/pages/leoes/index.tsx" /* webpackChunkName: "component---src-pages-leoes-index-tsx" */),
  "component---src-pages-madeinbrazil-index-tsx": () => import("./../../../src/pages/madeinbrazil/index.tsx" /* webpackChunkName: "component---src-pages-madeinbrazil-index-tsx" */),
  "component---src-pages-oito-index-tsx": () => import("./../../../src/pages/oito/index.tsx" /* webpackChunkName: "component---src-pages-oito-index-tsx" */),
  "component---src-pages-portfolio-index-tsx": () => import("./../../../src/pages/portfolio/index.tsx" /* webpackChunkName: "component---src-pages-portfolio-index-tsx" */),
  "component---src-pages-starbucks-index-tsx": () => import("./../../../src/pages/starbucks/index.tsx" /* webpackChunkName: "component---src-pages-starbucks-index-tsx" */)
}

